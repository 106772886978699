@import "mixins";
@import "colors";
@import "./_config";

.solution-wrapper {
    padding: 16px 8px;
    flex-wrap: wrap;
    align-items: center;
    max-width: 710px;
    margin: 0 auto;
    justify-content: center;
}
solution-tile {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.solution {
    @include flex(column);
    align-items: stretch;
    padding: 25px 25px 25px 0;
    &__body {
        background-color: color(grey, lighter);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        min-width: 145px;
        min-height: 140px;
        justify-content: center;
        img {
            height: 60px;
            width: auto;
            padding: 0;
        }
    }
    &__footer {
        display: flex;
        background-color: $color_blue;
        border-top: 1px solid $color-smoke-white;
        &--navigation {
            color: $color_blue;
            padding: 8px 10px;
            // border-top: 4px solid color(skyblue, dark);
            cursor: pointer;
            color: $color_white;
            align-items: center;
            justify-content: center;
            display: flex;
            width: 100%;
            position: relative;
            font-size: 14px;
            font-family: $font-family-fsalbert-bold;
        }
    }
}
