// Using it to manage color palette.
@function color($color, $tone) {
    @if map-has-key($palettes,
    $color) {
        $color: map-get($palettes, $color);
        @if map-has-key($color,
        $tone) {
            $tone: map-get($color, $tone);
            @return $tone;
        }
        @return null;
    }
    @return null;
}


@function color_new($color, $tone) {
    @if map-has-key($palettes_new,
    $color) {
        $color: map-get($palettes_new, $color);
        @if map-has-key($color,
        $tone) {
            $tone: map-get($color, $tone);
            @return $tone;
        }
        @return null;
    }
    @return null;
}

// Color variables - (Direct change in the color varible can cause the change in color pallets, So handle with care).
$color_white : #ffffff;
$color_black: #000000;
$color_red: #ff0000;
$color_yellow: #ffd700;
$color_aqua: #00FFFF;
$color_dark_sky_blue: #00BFFF;
$color_green: #008000;
$color_orange: #ff9933;
$color_blue: #10069F;
$label_color: #282828;
$dim_gray: #696969;
$color_white_disable: #A7A9AC;
$color_off_white: #F3F2FA;
$color_grey_dark: #575756;
$color_grey_base: #979797;

//////////////////////////////////////

$palettes: (
    grey: (
        darker: lighten($color_black, 20%), // #333
        dark: lighten($color_black, 60%), // #999, #999f9f
        base: lighten($color_black, 80%), // #ccc
        light: lighten($color_black, 92%), // #ececec, #ebebeb
        lighter: lighten($color_black, 95.9%), // #f6f6f6, #f5f5f5
    ),
    red: (
        darker: #ff0000,
        light: #E36A79
    ),
    aqua: (
        dark: #008998,
        base: #58B3BE,
        light: #bbe0e4,
        lighter: lighten(#58B3BE, 37%) // #e0f1f3
    ),
    skyblue: (
        dark: #2794B2,
        base: #009ddb,
    ),
    blue: (
        base: #10069F,
    ),
    yellow: (
        base: $color_yellow
    ),
    green: (
        base: $color_green,
        lighter: #92C387
    ),
    orange: (
        base: #ff9933
    )
);

$palettes_new: (
    grey: (
        button_disable: #a7a9ac,
        label: #282828,
        label_float: #696969,
        dark: #575756,
        dim: #696969
    ),
    blue: (
        diversey_blue: #10069F,
    ),
    white: (
        base: #FFFFFF,
        background_off_white: #F3F2FA
    ),
    red: (
        error: #FF0000,
        error_border: indianred
    )
);
