
// Global color Variables.

$white: #FFFFFF;
$black: #000;
$color_skyblue: rgba(20,139,188,0.2);
$body-bg-color: #ececec;
$light-grey: #e0e0e0;
$temp-base-font-color:	#55696B;
$host-primary-color: #009dda;
$breadcrumb-border-color: #d8d8d8;
$color_darkskyblue: #009ddb;
$color-red :#F00;
$color_green: #90c48f;
$color_btn_red: #E36A79;
$color_summary_block: #f8f8f8;
$color_lightblue: #99d8f1;
$color_summary-label: #999F9F;
$color_summary-value: #3D4953;
$color_realtime-label: #7d8c8e;
$color_realtime-value: #4a6062;
$color_realtime-image: #eeeeee;
$color_realtime-border: #e7e7e7;
$color_background-blue: #008998;
$color-smoke-white: #f4f4f4;
$color-light-grey: #ccc;
$color_landing_tile: #58B3BE;

//base font-face source path
$base-font-path: "../assets/font/";

//default font-size (in px)
$base-font-size: 10;

//default font-family
$font-family-arial: 'arial';
$base-font-family: 'FSAlbertArabic-Regular';


//new design
$font-family-fsalbert-regular: 'FSAlbertArabic-Regular';
$font-family-fsalbert-thin: 'FSAlbertArabic-Thin';
$font-family-fsalbert-light: 'FSAlbertArabic-Light';
$font-family-fsalbert-bold: 'FSAlbertArabic-Bold';
$font-family-fsalbert-extraBold: 'FSAlbertArabic-ExtraBold';

//available font-faces
$font-face-values: (fsalbert-regular: FSAlbertArabic, fsalbert-thin: FSAlbertArabic-Thin, fsalbert-light: FSAlbertArabic-Light, fsalbert-bold: FSAlbertArabic-Bold, fsalbert-extraBold: FSAlbertArabic-ExtraBold);

//available font formats
$font-formats: (eot: embedded-opentype, woff: woff, ttf: truetype);

//multipurpose 0's, 1's & 2's
$base-values: (0, 1, 2);

//available width and height values in percentage
$percentage-values: (33: 33.33, 66: 66.66, 1: 1, 5: 5, 10: 10, 15: 15, 20: 20, 25: 25, 30: 30, 35: 35, 40: 40, 45: 45, 50: 50, 55: 55, 60: 60, 65: 65, 70: 70, 75: 75, 80: 80, 85: 85, 90: 90, 95: 95, 100: 100);

//available padding and margin values
$pad-mar-values: (2, 3, 4, 5, 6, 8, 12, 16, 20, 24, 36);

//available space side values
$elem-sides: (all: all, v: vertical, h: horizontal, t: top, r: right, b: bottom, l: left);

//available flex justify-content values for containers
$flex-jc-values: (start: flex-start, end: flex-end, center: center, sb: space-between, sa: space-around, inherit: inherit);

//available flex align-items values for containers & align-self values for flex children
$flex-ai-as-values: (start: flex-start, end: flex-end, center: center, stretch: stretch, base: baseline, inherit: inherit);

//available flex-direction values
$flex-direction-values: (row: row, row-r: row-reverse, col: column, col-r: column-reverse);

//available flex-wrap values
$flex-wrap-values: (w: wrap, wr: wrap-reverse, n: nowrap);

// Diversey Custom Font-Icon
$icomoon-font-path: "../assets/font" !default;
// $icomoon-font-path: "/assets/font" !default;

$icon-administration: "\e903";
$icon-alarm: "\e904";
$icon-assets: "\e905";
$icon-caret-left: "\e906";
$icon-caret-right: "\e907";
$icon-cash: "\e908";
$icon-close: "\e901";
$icon-dropdown-arrow: "\e909";
$icon-energy-consumption: "\e913";
$icon-exclamation: "\e90a";
$icon-export: "\e90b";
$icon-fill-water: "\e914";
$icon-home: "\e900";
$icon-live: "\e90c";
$icon-non-compliant: "\e90d";
$icon-options: "\e915";
$icon-parameters: "\e90e";
$icon-reports: "\e90f";
$icon-rinse-water: "\e916";
$icon-search: "\e910";
$icon-share: "\e911";
$icon-tick: "\e912";
$icon-washcycles: "\e902";
$icon-washcycles2: "\e917";
