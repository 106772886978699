  /*=imports=*/

@import "scss/config";
@import "scss/mixins";
@import "scss/colors";
@import "scss/config";


* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

ul, ol{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a, a:hover, a:active, a:visited {
  color: #000;
  text-decoration: none;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  font-family: $base-font-family;
  font-size: 10px;
  font-weight: 300;
}

body {
  background-color: $body-bg-color;
  margin-bottom: 3.5em;
  font-family: $font-family-fsalbert-regular;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
}

app-root {
  height: 100vh;
}

/*===== document end =====*/

/*===== flex-container start =====*/

.container {
  max-width: 100%;
  @include flex();
}

//no outline
.no-outline {
  &,
  & * {
    outline: 0;
  }
}

input{
  outline: 0;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  z-index: 99;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  min-width: 100%;
  background-color: $white;
  > li.dropdown-item {
    padding: 6px 4px;
    &:not(:last-child) {
      margin-bottom: 0.7em;
      border-bottom: 1px solid #696969;
    }
    .item-container {
      @include flex();
    }
  }
  .item-container {
    input[type="checkbox"],
    input[type="radio"] {
      flex-shrink: 0;
      &+label {
        line-height: 1.5;
        height: inherit;
        margin-left: 3px;
      }
    }
  }
}

.open {
  .dropdown-menu {
    @include flex(col);
  }
}

.no-data {
    margin: auto;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #cd5c5c;
    margin: auto;
}

.message-success {
  color: #658F34;
}

.range_inputs .btn {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border: none;
  cursor: pointer;
  background-color: #009dda;
  color: $white;
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.grey-bg-loader {
  background: rgba(0,0,0,0.3);
}

.site-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  .loader {
    position: absolute;
    border: 8px solid #f3f3f3;
    border-top: 8px solid color_new(blue, diversey_blue);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}
