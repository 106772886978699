// ==== flex related ====
//for displaying flex with optional direction
@mixin flex($direction: row) {
    display: flex;
    @if ($direction==col) {
        flex-direction: column;
    }
    @else if ($direction==row-r) {
        flex-direction: row-reverse;
    }
    @else if ($direction==col-r) {
        flex-direction: column-reverse;
    }
    @else {
        flex-direction: $direction;
    }
}

@mixin arrow-down($color, $position: 95% 45%) {
    // background-image: url('data:image/svg+xml;utf8,<svg fill="#{$color}" height="1024" width="767.5" xmlns="http://www.w3.org/2000/svg"><path d="M0 384l383.75 383.75L767.5 384H0z"/></svg>');
    @if $color==$color_background-blue {
        background-image: url("/assets/images/dropdown-arrow.png");
    }
    @else if $color==$white {
        background-image: url("/assets/images/triangle-down-white.png");
    }
    @else if $color==$color_landing_tile {
        background-image: url("/assets/images/triangle-down-white.png");
    }
    @else {
        background-image: url("/assets/images/dropdown-arrow.png");
    }
    background-repeat: no-repeat; // background-size: 8px 14px;
    background-size: auto;
    background-position: $position;
    background-color: $white;
}

//for justify-content on flex conatiners
@mixin flex-jc($arg: start) {
    @if ($arg==start) {
        justify-content: flex-start;
    }
    @else if ($arg==end) {
        justify-content: flex-end;
    }
    @else if ($arg==center) {
        justify-content: center;
    }
    @else if ($arg==sb) {
        justify-content: space-between;
    }
    @else if ($arg==sa) {
        justify-content: space-around;
    }
    @else {
        justify-content: $arg;
    }
}

//for align-items on flex conatiners
@mixin flex-ai($arg: stretch) {
    @if ($arg==start) {
        align-items: flex-start;
    }
    @else if ($arg==end) {
        align-items: flex-end;
    }
    @else if ($arg==center) {
        align-items: center;
    }
    @else if ($arg==base) {
        align-items: baseline;
    }
    @else {
        align-items: $arg;
    }
}

//for align-self on flex children
@mixin flex-as($arg: start) {
    @if ($arg==start) {
        align-self: flex-start;
    }
    @else if ($arg==end) {
        align-self: flex-end;
    }
    @else if ($arg==base) {
        align-self: baseline;
    }
    @else {
        align-self: $arg;
    }
}

//for flex-wrap on flex conatiners
@mixin flex-wrap($arg: w) {
    @if ($arg==w) {
        flex-wrap: wrap;
    }
    @else if ($arg==wr) {
        flex-wrap: wrap-reverse;
    }
    @else if ($arg==n) {
        flex-wrap: nowrap;
    }
    @else {
        flex-wrap: $arg;
    }
}

// ==== margin, padding & border related ====
//for padding
@mixin pad($size,
$side: all,
$unit: px) {
    @if ($side==all) {
        padding: #{$size}px;
    }
    @else {
        @if $side==v {
            @include pad($size,
            t,
            $unit);
            @include pad($size,
            b,
            $unit);
        }
        @else if $side==h {
            @include pad($size,
            l,
            $unit);
            @include pad($size,
            r,
            $unit);
        }
        @else if $side==t {
            padding-top: #{$size}#{$unit};
        }
        @else if $side==r {
            padding-right: #{$size}#{$unit};
        }
        @else if $side==b {
            padding-bottom: #{$size}#{$unit};
        }
        @else if $side==l {
            padding-left: #{$size}#{$unit};
        }
    }
}

//for margin
@mixin mar($size,
$side: all,
$unit: px) {
    @if ($side==all) {
        margin: #{$size}px;
    }
    @else {
        @if $side==v {
            @include mar($size,
            t,
            $unit);
            @include mar($size,
            b,
            $unit);
        }
        @else if $side==h {
            @include mar($size,
            l,
            $unit);
            @include mar($size,
            r,
            $unit);
        }
        @else if $side==t {
            margin-top: #{$size}#{$unit};
        }
        @else if $side==r {
            margin-right: #{$size}#{$unit};
        }
        @else if $side==b {
            margin-bottom: #{$size}#{$unit};
        }
        @else if $side==l {
            margin-left: #{$size}#{$unit};
        }
    }
}

@mixin font-face($name,
$weight: normal,
$style: normal) {
    @font-face {
        font-family: $name;
        font-style: $style;
        font-weight: $weight;
        @content;
    }
}

@mixin font($name,
$size,
$unit: rem,
$weight: 300) {
    font-family: $name;
    font-size: $size + $unit;
    font-weight: $weight;
}

@mixin dropdown-menu($placement: l) {
    .dropdown-menu {
        @if ($placement==l) {
            left: 0;
        }
        @else if($placement==r) {
            right: 0;
        }
        @content;
    }
}
