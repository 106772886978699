// Styling for Tables for throughout the application.

.table {
  flex-wrap: no-wrap;
  -webkit-flex-wrap: no-wrap;
  width: 100%;
  border: 1px solid $light-grey;
  justify-content: space-between;
  .operations {
    width: 100px;
    i {
      padding: 0px 2px;
    }
  }
  .fa-caret-down {
    margin-left: 5px;
    // color: #58B3BE - 60;
    color: #58B3BE 
  }
  .pagination-container {
    @include flex();
    li.page-item {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.fa-caret-down {
  cursor: pointer;
}

.table-header-row-wrap {
  text-transform: uppercase;
  font-size: 13px;
  font-family: $font-family-arial;
  font-weight: 100;
  padding: 7px 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1 0 0;
  border-right: 1px solid $white;
}

.table-header {
  background-color: #58B3BE;
  color: $white;
  flex-wrap: nowrap;
}

.table-body {
  background-color: $white;
  min-height: 100px;
  display: flex;
  display: -webkit-box;
  overflow-y: auto;
  height: 300px;
  .row {
    @include flex(row);
    // align-items: center;
    // flex-direction: row;
    border: 1px solid $light-grey;
    border-top: none;
    background-color: #f8f8f8;
    .site-list {
      display: flex;
      flex: 1 0 0;
    }
    .site-details {
      // height: 40px;
      background-color: $white;
      // flex-direction: column;
    }
  }
  .row:nth-child(even) {
    background-color: #EEE;
  }
  .column {
    padding: 6px 10px;
    flex: 1 0 0;
    border-right: 1px solid $light-grey;
    &:last-child {
      border-right: none;
    }
  }
}

.table-footer {
  font-size: 12px;
  justify-content: space-between;
  margin-top: 10px;
}
