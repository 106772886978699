//importing reequired sass files
@import "base";
@import "fonts";
@import "colors";

@each $key,
$val in $flex-direction-values {
    .flex-#{$key} {
        @include flex($val);
    }
}
// ==== main csss start ====
// converting list variables into classes accordingly

//for padding, margin & border classes
@each $key, $val in $elem-sides{
  @if $key == all {
    .no-pad{
      @include pad(0);
    }
    .no-mar{
      @include mar(0);
    }
  } @else {
   .no-pad-#{$key}{
      @include pad(0, $key);
    }
    .no-mar-#{$key}{
      @include mar(0, $key);
    }
  }
  @each $size in $pad-mar-values{
    @if $key == all {
      .pad-#{$size}{
        @include pad($size);
      }
      .mar-#{$size}{
        @include mar($size);
      }
    } @else {
      .pad-#{$size}-#{$key}{
        @include pad($size, $key);
      }
      .mar-#{$size}-#{$key}{
        @include mar($size, $key);
      }
    }
  }
}
//for justify content of flex containers
@each $key, $val in $flex-jc-values{
  .flex-jc-#{$key}{
    @include flex-jc($val);
  }
}
//for align items of flex containers & align self of flex children
@each $key, $val in $flex-ai-as-values{
  .flex-ai-#{$key}{
    @include flex-ai($val);
  }
  .flex-as-#{$key}{
    @include flex-as($val);
  }
}
//for flex direction of flex containers
@each $key, $val in $flex-direction-values{
  .flex-#{$key}{
    @include flex($val);
  }
}
//for flex wrap of flex containers
@each $key, $val in $flex-wrap-values{
  .flex-wrap-#{$key}{
    @include flex-wrap($val);
  }
}

// Bootstrap Date Time Picker
.intellidish-wrapper {
  datetime {
    .date,
    .timepicker {
      @include flex(row);
      align-items: center;
      margin-bottom: 10px;
      input {
        height: 30px;
        flex-grow: 1;
        padding: 5px 10px;
        border: 1px solid $breadcrumb-border-color;
      }
      .input-group-addon {
        @include flex(column);
        justify-content: center;
        max-width: 34px;
        height: 30px;
        padding: 0 10px;
        background: $body-bg-color;
        border: 1px solid $breadcrumb-border-color;
        border-left: 0;
        border-radius: 0 4px 4px 0;
      }
      .fa-clock-o {
        @include flex(row);
        justify-content: center;
      }
    }
    .timepicker {
      margin-bottom: 0;
    }
  }
  .custom-date-dropdown {
    // Filter should be on top level
    z-index: 101;
    .datepicker-dropdown {
      border: 1px solid $breadcrumb-border-color;
      table tr td.disabled,
      table tr td.disabled:hover {
        color: #a7a7a7;
        background: #f7f7f7;
      }
    }
  }
  .bootstrap-timepicker-widget {
    border: 1px solid $breadcrumb-border-color;
    &.dropdown-menu {
      padding: 0;
    }
    table td {
      height: auto;
      a {
        padding: 0;
      }
    }
    &:before,
    &:after {
      display: none !important;
    }
  }
}

//Shift list pagination
.shift-pagination{
  li.page-item{
    font-weight: normal;
  }
  li.page-item.active{
    font-weight: bolder;
    text-decoration: underline;
  }
}

.loader {
  position: absolute;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-pack: distribute;
  border: 8px solid #f3f3f3;
  border-top: 8px solid color_new(blue, diversey_blue);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  top: 30%;
  animation: spin 2s linear infinite;
  z-index: 1;
  left: 45%;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.pdfViewer {
  .page,
  .canvasWrapper,
  .textLayer {
    width: 100% !important; // overiding inline styling
  }
}

/**
 ** Overriding default CSS of `ng-select`
 ** it can't be achieve if we write the respective css in individual component.
 ** So don't remove it.
 **/

 ng-select {
  height: 18px; // to align the vertical fields border.
  &.disabled {
      opacity: 1;
  }
  .ng-clear-wrapper {
      display: none;
  }
  &.ng-select.ng-select-single {
      .ng-select-container {
          border: none;
          background: transparent;
          height: inherit;
          min-height: inherit;
          &:hover {
              box-shadow: none;
          }
          .ng-value-container {
              padding: 0;
              .ng-input {
                  background: transparent;
                  padding: 0;
              }
              .ng-placeholder {
                  color: #696969;
              }
          }
      }
  }
  &.ng-select-opened {
      .ng-select-container {
          .ng-value-container {
              .ng-input {
                  &:after {
                      transition: 0.5s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
                      transform: rotate(180deg);
                  }
              }
          }
      }
  }
  &:not(.disabled) {
      .ng-select-container {
          .ng-value-container {
              .ng-input {
                  &:after {
                      content: "";
                      width: 0;
                      height: 0;
                      border-left: 5px solid transparent;
                      border-right: 5px solid transparent;
                      border-top: 5px solid color_new(blue, diversey_blue);
                      position: absolute;
                      top: 30%;
                      right: 3%;
                      transition: 0.4s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
                  }
              }
          }
      }
  }
}

app-user {
    height: 100%;
    background: color_new(white, background_off_white);
}
