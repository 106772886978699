@import 'config';
@import 'mixins';
@import 'colors';

@import "~@ng-select/ng-select/themes/default.theme.css";

$color_button: #42A2C9;
.error {
    padding-left: 10px;
    color: $color-red;
}

#toast-container > div {
    opacity: 0.9;
}

.column {
    @include flex(column);
    align-items: stretch;
    width: 49.5%;
    .fields-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .system-unique-id {
            display: flex;
            flex-direction: row;
            background-color: $color-smoke-white;
        }
    }
}

.select-box-container {
    @include flex(row);
    justify-content: space-between;
    background: $white;
    align-items: center;
    padding: 0.75em;
    margin-bottom: 1em;
    min-height: 49px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    .multi-select-dropdown {
        flex: 0 0 55%;
        border: 1px solid $color-light-grey;
    }
}

.text-field-container {
    @include flex(row);
    justify-content: space-between;
    align-items: center;
    background: $white;
    padding: 0.75em;
    margin-bottom: 1em;
    min-height: 49px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    input[type=text],input[type=password],
    input[type=number] {
        padding: 0.7em;
        border: 1px solid $color-light-grey;
        background-color: $color-smoke-white;
        flex: 0 0 55%;
        max-height: 30px;
        font-size: 12px;
    }
}

label,
.label {
    // Add .label to style where we have custom elements. Eg: Multi select drodown
    font-size: 14px;
    font-weight: normal;
    color: $color_realtime-value;
}

.form-container {
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    .form-title {
        font-family: $font-family-fsalbert-light;
        font-size: 20px;
        color: $color_background-blue;
        margin-bottom: 1em;
    }
    .checkbox-field {
        cursor: pointer;
        label,
        input {
            cursor: pointer;
        }
        input {
            margin-right: 0.7em;
        }
    }
}

.form-section {
    font-family: $font-family-arial;
    font-size: 13px;
    .section-row {
        margin-bottom: 1.5em;
        width: 100%;
        // justify-content: space-between;
    }
    .flexible-layout {
        @include flex(row);
        width: 100%;
        .column {
            width: inherit;
            max-width: 32.5%;
            margin-right: 1em;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 49.5%;
        .fields-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .country-container {
                width: 49.5%;
                .field-row {
                    width: 100%;
                }
            }
            .static-text-area {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-smoke-white;
            }
        }
    }
    .column.map {
        border: 1px solid $color-smoke-white;
    }
}

.section-heading {
    padding: 7px;
    font-size: 14px;
    font-weight: normal;
    background-color: $color_summary_block;
    color: $color_realtime-value;
}

.submit-buttons-wrapper {
    justify-content: space-between;
}

.action-button-wrapper {
    border-top: 1px solid $light-grey;
    padding: 10px 0 0;
    margin: 2em 0 8em;
    display: flex;
    justify-content: space-between;
    button {
        background: $color_button;
        color: $white;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
        padding: 8px 40px;
        font-size: 14px;
        &.cancel {
            background: $color_btn_red;
            padding: 8px 32px;
        }
    }
}

.section-disabled {
    input,
    button,
    label {
        cursor: not-allowed;
        pointer-events: none;
    }
    i.fa-remove {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background: $color-smoke-white;
    float: right;
}

.two-column-layout {
    @include flex(row);
    justify-content: space-between;
}

.section {
    margin-bottom: 1em;
}

.control-error-container {
    color: $color_btn_red;
    > div:first-child {
        margin-top: 5px;
    }
}

select {
    overflow-y: overlay;
    // &::-ms-expand {
    //     display: none;
    // }
}

input:focus,
select:focus,
button:focus {
    outline: none;
    border-color: #719ECE;
}

app-loader.loader-class {
    display: flex;
    justify-content: center;
}

// Exceptions
// Wrapping text in IE10 as the text is long.
.timezone {
    max-width: 70%;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// End

// css for custom ng-select dropdown
/* Using Deep nesting to override plugin's CSS
(Plugin is providing deep nesting.)*/
ng-select.ng-select {
    &.custom {
        flex: 0 0 55%;
    }
    &.ng-select-single {
        .ng-select-container {
            cursor: text;
            border-radius: 0px;
            min-height: 30px;
            height: 30px;
            background-color: color(grey, lighter);
            .ng-value-container {
                .ng-value-label {
                    z-index: 1;
                    position: relative;
                }
                .ng-placeholder {
                    z-index: 1;
                    font-size: 12px;
                    color: darken(color(grey, dark), 7%);
                }
                .ng-input {
                    font-size: 12px;
                    padding-right: 10px;
                    top: 0;
                    height: 100%;
                    background-color: color(grey, lighter);
                    input {
                        cursor: text;
                        height: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }
    .ng-arrow-wrapper {
        display: none;
    }
}
