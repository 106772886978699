/* constants used in whole web app */
//zero value
$zero: 0;
//unit value
$one: 1;
//two value
$two: 2;
//ten value
$ten: 10;
//quarter value
$quarter: 25;
//fifty value
$fifty: 50;
//ton value
$ton: 100;
//transparent color
$transparent-color: transparent;